<template>
    <div class="home">
        <h1>home</h1>
    </div>
</template>

<script>
export default {
    name: "home"
}
</script>

<style>

</style>